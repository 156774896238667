@import "./base.sass"
@import "./Basic.sass"

.access-professor-detail-tab
    @include access-card
    display: flex
    justify-content: space-around
    font-size: 22
    font-weight: 400
    padding: 36px 0 8px 0
    border-bottom: 2px solid $color_font_dark
    span
        cursor: pointer
    [active]
        font-weight: 600
    